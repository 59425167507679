<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">Mymovingway</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            {{ $t("Welcome") }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("Please sign-in to your account and start the adventure") }}!
          </b-card-text>

          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <PrecheckPersonEmail
              :$v="$v"
              :personData="personData"
              :isNext="isSubmit"
            />

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t("Password") }}</label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>{{ $t("Forgot Password?") }}</small>
                </b-link>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="login-password"
                  v-model="personData.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  :placeholder="$t('Şifre Giriniz')"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="isSubmit && !$v.personData.password.required"
                class="form-text text-danger"
                >{{ $t("The field is required") }}</small
              >
            </b-form-group>

            <!-- submit buttons -->
            <b-button type="submit" variant="primary" block>
              {{ $t("Sign in") }}
            </b-button>
          </b-form>
          <div style="margin-top: 15px; text-align: right; font-size: 10px;">
            <a :href="`${apiURL}/Files/KVKPolitikasi.pdf`" target="_blank">
              <small>{{
                $t("Kişisel Verilerin Korunması ve İşlenmesi Politikası")
              }}</small>
            </a>
          </div>

          <div style="margin-top: 5px; text-align: right; font-size: 10px;">
            <a :href="`${apiURL}/Files/CerezPolitikasi.pdf`" target="_blank">
              <small>{{ $t("Çerez Politikası") }}</small>
            </a>
          </div>
          <div
            style="font-size: 11px; bottom: -150px; right: 20px; position:absolute;"
          >
            <span class="text-dark">{{ $t("Last Updated Date") }}:</span>
            <b class="text-primary mr-1"> 27.07.2023</b>
            <span class="text-dark">{{ $t("Version") }}:</span>
            <b class="text-primary"> 1.1.0</b>
          </div>
          <b-card-text class="text-center mt-2" v-if="false">
            <span>{{ $t("New on our platform?") }} </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;{{ $t("Create an account") }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <!--    <b-row>-->

    <!--          <p class="version-text">-->
    <!--            <span class="text-dark">{{ $t("Last Updated Date") }}:</span>-->
    <!--            <b class="text-primary mr-1"> 19.04.2022</b>-->
    <!--            <span class="text-dark">{{ $t("Version") }}:</span>-->
    <!--            <b class="text-primary"> 1.0.0</b>-->
    <!--          </p>-->
    <!--    </b-row>-->
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import { email, required } from "vuelidate/lib/validators";
import PrecheckPersonEmail from "@/components/PrecheckPersonEmail/PrecheckPersonEmail";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    PrecheckPersonEmail,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      apiURL: "",
      status: "",
      personData: {
        email: "",
        password: "",
      },
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,

      isSubmit: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      if (this.$v.$invalid === false) {
        // Logout işleminde gerçekleştirilen auth bilgilerinin silinme işleminin aynısını gerçekleştiriyoruz
        sessionStorage.removeItem("auth/token");
        sessionStorage.removeItem("auth/optionList");
        sessionStorage.removeItem("auth/userInfo");
        sessionStorage.removeItem("userData");

        this.$store
          .dispatch("auth/token", {
            Email: this.personData.email,
            Password: this.personData.password,
          })
          .then((res) => {
            //giriş başarılı ise
            let optionList = this.$store.getters["auth/optionList"];
            let userInfo = this.$store.getters["auth/userInfo"];
            if (
              this.$route.query.name === "trainer-confirm" ||
              this.$route.query.name === "customer-confirm" ||
              this.$route.query.name === "facility-confirm" ||
              this.$route.query.name === "distributor-confirm" ||
              this.$route.query.name === "resend-invite-confirm"
            ) {
              this.$router.push(this.$route.query.path);
            } else {
              if (res == 2) {
                this.$router.push({ name: "select-login" });
              } else if (res == 1) {
                this.$store
                  .dispatch("auth/userInfo", {
                    name: userInfo[0].name,
                    last_name: userInfo[0].last_name,
                    person_id: userInfo[0].person_id,
                    email: userInfo[0].email,
                    menu_role_group_id: userInfo[0].menu_role_group_id,
                    group_name: userInfo[0].group_name,
                    company_branch_id: userInfo[0].company_branch_id,
                    company_branch_name: userInfo[0].company_branch_name,
                    company_branch_person_role_id:
                      userInfo[0].company_branch_person_role_id,
                  })
                  .then(() => {
                    this.$router.push({ name: "home" });
                  });
              } else {
                this.$functions.Messages.success(
                  "Lütfen giriş yapmak istediğiniz tesis ile iletişime geçiniz"
                );
              }
            }
          });
      } else {
        this.isSubmit = true;
      }
    },
  },
  validations: {
    personData: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  created() {
    this.apiURL = process.env.VUE_APP_API;
    //  console.log('query:' + this.$route.query);
    // console.log(this.$route.query);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.version-text {
  position: fixed;
  bottom: 0;
  right: 20px;
}
@media screen and (max-width: 770px) {
  .version-text {
    position: fixed;
    bottom: 0;
    right: 35px;
    font-size: 12px;
  }
}
</style>
